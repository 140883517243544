(function () {
    'use strict';

    var updateOrder = function (path, data) {
        $.ajax({
            type: 'POST',
            url: path,
            headers: {
                "x-csrf-token": $("#csrf-token").attr('value')
            },
            data: data,
            dataType: "json"
        });
    };

    var fixHelper = function (e, ui) {
        ui.children().each(function () {
            $(this).width($(this).width());
        });
        return ui;
    };

    $(".sortableproducts").sortable({
        distance: 10,
        cursor: "move",
        stop: function () {
            var result = $(this).sortable('toArray');
            var categoryId = $(this).attr('data-category-id');
            updateOrder('/admin/webshop/products/update-order', 'category=' + categoryId + '&data=' + JSON.stringify(result));
        },
        items: 'tr',
        helper: fixHelper
    }).disableSelection();

    $("#product_image_list").sortable({
        distance: 10,
        cursor: "move",
        stop: function () {
            var result = $(this).sortable('toArray');
            updateOrder('/admin/image/update-order', 'data=' + JSON.stringify(result));
        }
    });

    $(".sortablerecords").sortable({
        distance: 10,
        cursor: "move",
        stop: function () {
            var result = $(this).sortable('toArray');
            var componentId = $(this).attr('id');
            var postData = {
                data: JSON.stringify(result),
                page_id: $("#page_id").val()
            };
            updateOrder('/admin/page-contents/records/update-order/' + componentId, postData);
        },
        items: 'tr',
        helper: fixHelper
    }).disableSelection();

    $(".sortable_list").sortable({
        distance: 10,
        cursor: "move",
        stop: function () {
            var result = $(this).sortable('toArray');
            updateOrder('/admin/webshop/product-categories/update-order', 'data=' + JSON.stringify(result));
        }
    });

    $(".sortable_list_pages").sortable({
        distance: 10,
        cursor: "move",
        stop: function () {
            var result = $(this).sortable('toArray');
            updateOrder('/admin/pages/update-order', 'data=' + JSON.stringify(result));
        }
    });
})();