$(document).ready(function () {
    // generic button handlers
    $(".delete-button").on('click', '', code14cmsCallbacks.openDeleteButton);
    $(".dont-remove").on('click', '', code14cmsCallbacks.dontRemove);
    $(".delete_redirect_button").on('click', '', code14cmsCallbacks.deleteRedirect);

    // initiators
    $('.wysiwyg').redactor();

    $("i").tooltip();
});