/**
 * Created by harmjan on 03/03/16.
 */
var code14cmsCallbacks = (function () {
    var _openDeleteMessage,
        _getDeleteId,
        _getIdSuffix,
        _setDeleteConfirmationMessage,
        _deleteRedirect,
        _closeDeleteMessage;

    _openDeleteMessage = function (id, name, componentId) {
        _setDeleteConfirmationMessage(name, componentId);

        var idSuffix = _getIdSuffix(componentId);

        id = _getDeleteId(id);
        if (componentId !== undefined) {
            $("#object_id_" + componentId).val(id);
        } else {
            $("#object_id").val(id);
        }

        $("#delete_confirmation" + idSuffix).show(500);
    };

    _getIdSuffix = function (componentId) {
        var idSuffix = '';
        if (componentId !== undefined) {
            idSuffix = '_' + componentId;
        }
        return idSuffix;
    };

    _closeDeleteMessage = function () {
        $(".delete_confirmation").hide(500);
    };

    _getDeleteId = function (id) {
        return id.replace('delete_id_', '');
    };

    _setDeleteConfirmationMessage = function (pagename, componentId) {
        var idSuffix = _getIdSuffix(componentId);
        var currentContent = $("#delete_confirmation_message" + idSuffix).html();
        if (currentContent !== undefined) {
            if (pagename === undefined) {
                pagename = ''
            }
            var newContent = currentContent.replace(':pagename', pagename);
            $("#delete_confirmation_message" + idSuffix).html(newContent);
        }
    };

    _deleteRedirect = function (id) {
        var recordId = $("#record_id").val();
        $.get('/admin/routing/redirectmanager/deleteSystemRedirect/' + id);
        $("#redirect_" + id).remove();
    };

    return {
        openDeleteButton: function (e) {
            e.preventDefault();
            _openDeleteMessage($(this).attr('id'), $(this).attr('data-name'), $(this).attr('component-id'));
        },

        dontRemove: function (e) {
            e.preventDefault();
            _closeDeleteMessage();
            return false;
        },
        deleteRedirect: function (e) {
            e.preventDefault();
            var id = $(this).attr('redirect-id');
            _deleteRedirect(id);
        },
    }
})();